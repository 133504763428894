import React, { useEffect } from "react"
import { graphql } from "gatsby"

// GQL Fragments
import { QueryFragments } from "../GraphQL/queryFragments" //eslint-disable-line

// Hooks, Querys & State
import { useAppState } from "../state/appState"

import { PageMeta } from "../components/pageMeta"
import BlockZone from "../components/blocks/blockZone"

const StandardPage = ({ data: { page } }) => {

  const {
    setGlobalTheme,
    toggleHideFooter,
    toggleHideHeader,
    toggleHeaderUnderline,
  } = useAppState()
  
  const {
    meta,
    pageBlocks,
    theme,
    main: { hideFooter },
  } = page || {}  

  const pageTheme = theme.pageTheme

  useEffect(() => {
    if (pageTheme) {
      setGlobalTheme(pageTheme)
      toggleHideHeader(false)
      toggleHeaderUnderline(true)
    }
  }, [setGlobalTheme, pageTheme, toggleHideHeader, toggleHeaderUnderline])

  useEffect(() => {
    if (hideFooter) {
      toggleHideFooter(hideFooter)
    } else {
      toggleHideFooter(false)
    }
  }, [toggleHideFooter, hideFooter])
  
  return (
    <div className="">
      <PageMeta {...meta} />
      {pageBlocks && <BlockZone {...pageBlocks} />}
    </div>
  )
}

export default StandardPage

export const pageQuery = graphql`
  query standardPageQuery($slug: String!) {
    page: sanityStandardPage(main: { slug: { current: { eq: $slug } } }) {
      pageBlocks {
        ...BlockZoneBlocks
      }
      main {
        hideFooter
      }
      theme {
        pageTheme
      }
      meta {
        ...MetaCardFields
      }
    }
  }
`
